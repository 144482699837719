import React, { lazy, Suspense } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import * as COMMONS from "common/common"
import { AnimatePresence } from "framer-motion"
import { ErrorBoundaryComponent, TopProgressBar } from "components"

const MinimalLayout = lazy(() => import("./layouts/Minimal"))
const AdminMainLayout = lazy(() => import("./layouts/AdminMain"))
const ClientMainLayout = lazy(() => import("./layouts/ClientMain"))

const AdminLoginView = lazy(() => import("./views/Admin/Login"))
const AdminMembersView = lazy(() => import("./views/Admin/Members"))
const AdminSettingsView = lazy(() => import("./views/Admin/Settings"))
const AdminAudiencesView = lazy(() => import("./views/Admin/Audiences"))
const AdminAudiencesSearchView = lazy(() =>
  import("./views/Admin/AudienceSearch")
)
const ClientLoginView = lazy(() => import("./views/Client/Login"))
const ClientPosLoginView = lazy(() => import("./views/Client/PosLogin"))
const ClientEcLoginView = lazy(() => import("./views/Client/EcLogin"))
const ClientPosConfirmView = lazy(() => import("./views/Client/PosConfirm"))
const ClientPosOrderDetailsView = lazy(() =>
  import("./views/Client/PosOrderDetail")
)
const ClientRegisterView = lazy(() => import("./views/Client/Register"))
const ClientRegisterConfirmView = lazy(() =>
  import("./views/Client/RegisterConfirm")
)
const ClientProfileUpdateView = lazy(() =>
  import("./views/Client/ProfileUpdate")
)
const ClientMembershipView = lazy(() => import("./views/Client/Membership"))
const ClientTempMembershipView = lazy(() =>
  import("./views/Client/TempMembership")
)

const ClientRegistrationsView = lazy(() =>
  import("./views/Client/Registrations")
)
const ClientRegistrationDetailView = lazy(() =>
  import("./views/Client/RegistrationDetail")
)

const LineAccessView = lazy(() => import("./views/LineAccess"))
const LineFriendView = lazy(() => import("./views/LineFriend"))
const PermissionErrorView = lazy(() => import("./views/PermissionError"))
const NotFoundView = lazy(() => import("./views/NotFound"))

const Router = () => {
  return (
    <ErrorBoundaryComponent>
      <Suspense fallback={<TopProgressBar />}>
        <AnimatePresence mode="wait">
          <Routes>
            <Route
              path="/"
              element={<Navigate replace to={COMMONS.ADMIN_LOGIN_ROUTE} />}
            />
            <Route
              path={COMMONS.ADMIN_LOGIN_ROUTE}
              element={
                <MinimalLayout>
                  <AdminLoginView />
                </MinimalLayout>
              }
            />
            <Route
              path={COMMONS.ADMIN_MEMBERS_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminMembersView />
                </AdminMainLayout>
              }
            />
            <Route
              path={COMMONS.ADMIN_CAMPAIGN_MEMBERS_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminMembersView />
                </AdminMainLayout>
              }
            />
            <Route
              path={COMMONS.ADMIN_AUDIENCES_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminAudiencesView />
                </AdminMainLayout>
              }
            />
            <Route
              path={COMMONS.ADMIN_AUDIENCES_SEARCH_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminAudiencesSearchView />
                </AdminMainLayout>
              }
            />

            <Route
              path={COMMONS.ADMIN_SETTINGS_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminSettingsView />
                </AdminMainLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_LOGIN_ROUTE}/:liffId`}
              element={
                <MinimalLayout>
                  <ClientLoginView />
                </MinimalLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_POS_LOGIN_ROUTE}/:liffId`}
              element={
                <MinimalLayout>
                  <ClientPosLoginView />
                </MinimalLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_EC_LOGIN_ROUTE}/:liffId`}
              element={
                <MinimalLayout>
                  <ClientEcLoginView />
                </MinimalLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_POS_CONFIRM_ROUTE}/:liffId`}
              element={
                <MinimalLayout>
                  <ClientPosConfirmView />
                </MinimalLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_REGISTER_ROUTE}/:liffId`}
              element={
                <MinimalLayout>
                  <ClientRegisterView />
                </MinimalLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_REGISTER_CONFIRM_ROUTE}/:liffId`}
              element={
                <MinimalLayout>
                  <ClientRegisterConfirmView />
                </MinimalLayout>
              }
            />

            <Route
              path={`${COMMONS.CLIENT_PROFILE_UPDATE_ROUTE}/:liffId`}
              element={
                <ClientMainLayout>
                  <ClientProfileUpdateView />
                </ClientMainLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_MEMBERSHIP_ROUTE}/:liffId`}
              element={
                <ClientMainLayout>
                  <ClientMembershipView />
                </ClientMainLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_TEMP_MEMBERSHIP_ROUTE}/:liffId`}
              element={
                <ClientMainLayout>
                  <ClientTempMembershipView />
                </ClientMainLayout>
              }
            />

            <Route
              path={`${COMMONS.CLIENT_REGISTRATIONS_ROUTE}/:liffId`}
              element={
                <ClientMainLayout>
                  <ClientRegistrationsView />
                </ClientMainLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_REGISTRATION_DETAIL_ROUTE}/:liffId/:registrationId`}
              element={
                <ClientMainLayout>
                  <ClientRegistrationDetailView />
                </ClientMainLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_POS_ORDER_DETAIL_ROUTE}/:liffId`}
              element={
                <ClientMainLayout>
                  <ClientPosOrderDetailsView />
                </ClientMainLayout>
              }
            />
            <Route
              path={COMMONS.LINE_ACCESS_ROUTE}
              element={
                <MinimalLayout>
                  <LineAccessView />
                </MinimalLayout>
              }
            />
            <Route
              path={COMMONS.LINE_FRIEND_ROUTE}
              element={
                <MinimalLayout>
                  <LineFriendView />
                </MinimalLayout>
              }
            />
            <Route
              path={COMMONS.PERMISSION_ERROR_ROUTE}
              element={
                <MinimalLayout>
                  <PermissionErrorView />
                </MinimalLayout>
              }
            />
            <Route
              path={COMMONS.NOT_FOUND_ROUTE}
              element={
                <MinimalLayout>
                  <NotFoundView />
                </MinimalLayout>
              }
            />
            <Route
              path="*"
              element={<Navigate replace to={COMMONS.NOT_FOUND_ROUTE} />}
            />
          </Routes>
        </AnimatePresence>
      </Suspense>
    </ErrorBoundaryComponent>
  )
}

export default Router
