import { useState } from "react"
import {
  Button,
  Form,
  Select,
  Input,
  message,
  Modal,
  Radio,
  DatePicker,
  Divider,
} from "antd"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { motion } from "framer-motion"

import * as API from "common/api"
import * as COMMONS from "common/common"
import { TapAnimationComponent, NumericInputComponent } from "components"
import moment from "moment"
import "moment/locale/ja"
import locale from "antd/es/date-picker/locale/ja_JP"

moment.locale("ja")
const { Option } = Select

const MemberAddModal = (props) => {
  const { isMemberAddModalVisible, hideMemberAddModal } = props
  const [registerProfileForm] = Form.useForm()
  const queryClient = useQueryClient()

  const isMountedRef = COMMONS.USE_IS_MOUNTED_REF()
  const [uniqueEmailEcError, setUniqueEmailEcError] = useState(undefined)
  const [uniqueEmailError, setUniqueEmailError] = useState(undefined)

  const postalSearchHandler = () => {
    const postalCode = registerProfileForm.getFieldValue("postalCode")

    if (postalCode.length === 7) {
      API.GET_ADDRESS_BY_POSTAL_CODE(postalCode).then((text) => {
        const matcher = text.match(/({".*"]})/)

        if (matcher) {
          const json = JSON.parse(matcher[0])
          const address = json[postalCode]
          if (address && address[0] && address[1]) {
            const index = address[0] - 1

            registerProfileForm.setFieldsValue({
              prefecture: `${COMMONS.PREFECTURES[index]["value"]}`,
              address1: `${COMMONS.PREFECTURES[index]["label"]}`,
              address2: `${address[1]}${address[2]}${
                address[3] ? address[3] : ""
              }`,
            })
          } else {
            message.warning(COMMONS.WARN_POSTAL_CODE_WRONG_MSG)
          }
        }
      })
    }
  }
  const registerProfileMutation = useMutation(API.CLIENT_CREATE_PROFILE, {
    onSuccess: (response) => {
      if (isMountedRef.current) {
        message.success(COMMONS.SUCCESS_UPDATE_MSG)
        queryClient.invalidateQueries({
          queryKey: [API.QUERY_KEY_CLIENT_PERSONAL_INFO],
        })
        hideMemberAddModal()
      }
    },
    onError: (error) => {
      if (error?.response?.status === COMMONS.RESPONSE_CONFLICT_ERROR) {
        setUniqueEmailError(COMMONS.ERROR_EMAIL_UNIQUE_MSG)
      } else if (
        error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR
      ) {
        setUniqueEmailEcError(COMMONS.ERROR_EC_EMAIL_USER_EXIST)
      } else {
        message.error({
          content: COMMONS.ERROR_SYSTEM_MSG,
          key: COMMONS.MESSAGE_SYSTEM_ERROR_KEY,
        })
      }
    },
  })
  const registerProfileHandler = (data) => {
    let paramData = {
      lastName: data.lastName,
      firstName: data.firstName,
      lastNameKana: data.lastNameKana,
      firstNameKana: data.firstNameKana,
      telephone: data.telephone,
      postalCode: data.postalCode,
      prefecture: data.address1,
      address: data.address2,
      building: data.address3,
      email: data.email,
      notifyPC: data.notifyPC,
      emailMobile: data.emailMobile,
      notifyMobile: data.notifyMobile,
      birthDate: data?.birthDate
        ? moment(data?.birthDate).format("YYYY/MM/DD")
        : null,
      birthMonth: data.birthMonth,
      gender: data.gender,
    }

    registerProfileMutation.mutate(paramData)
  }
  return (
    <>
      <Modal
        title="新規顧客情報の登録"
        open={isMemberAddModalVisible}
        onCancel={hideMemberAddModal}
        footer={null}
        centered
        width={800}
        destroyOnClose
        bodyStyle={{
          maxHeight: "85vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Divider className="mt-2 mb-8 font-bold">お客様情報</Divider>
        <motion.div
          className="flex flex-col justify-center"
          variants={COMMONS.ANIMATION_VARIANT_STAGGER_CONTAINER}
          initial="hidden"
          animate="show"
          exit="hidden"
        >
          <Form
            form={registerProfileForm}
            onFinish={registerProfileHandler}
            size="large"
            layout="horizontal"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            labelAlign="left"
            requiredMark={false}
            colon={false}
            scrollToFirstError
            initialValues={{
              notifyPC: 1,
              notifyMobile: 1,
            }}
          >
            <Form.Item required label="氏名（漢字）" className="px-6  mb-0">
              <Form.Item
                name="lastName"
                className="inline-block mr-2"
                style={{ width: "calc(50% - 0.25rem)" }}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "姓を入力してください",
                  },
                  {
                    max: 50,
                    message: "50文字未満である必要があります",
                  },
                ]}
              >
                <Input
                  placeholder="例：山田"
                  onPressEnter={(e) => e.preventDefault()}
                />
              </Form.Item>
              <Form.Item
                name="firstName"
                className="inline-block"
                style={{ width: "calc(50% - 0.25rem)" }}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "名を入力してください",
                  },
                  {
                    max: 50,
                    message: "50文字未満である必要があります",
                  },
                ]}
              >
                <Input
                  placeholder="例：太郎"
                  onPressEnter={(e) => e.preventDefault()}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item required label="氏名（フリガナ）" className="px-6 mb-0">
              <Form.Item
                name="lastNameKana"
                className="inline-block mr-2"
                style={{ width: "calc(50% - 0.25rem)" }}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "セイを入力してください",
                  },
                  {
                    max: 50,
                    message: "50文字未満である必要があります",
                  },
                  {
                    pattern: new RegExp("[\u30a0-\u30ff]"),
                    message: "全角カタカナで入力してください",
                  },
                ]}
              >
                <Input
                  placeholder="例：ヤマダ"
                  onPressEnter={(e) => e.preventDefault()}
                />
              </Form.Item>
              <Form.Item
                name="firstNameKana"
                className="inline-block"
                style={{ width: "calc(50% - 0.25rem)" }}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "メイを入力してください",
                  },
                  {
                    max: 50,
                    message: "50文字未満である必要があります",
                  },
                  {
                    pattern: new RegExp("[\u30a0-\u30ff]"),
                    message: "全角カタカナで入力してください",
                  },
                ]}
              >
                <Input
                  placeholder="例：タロウ"
                  onPressEnter={(e) => e.preventDefault()}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="電話番号"
              name="telephone"
              className="px-6"
              rules={[
                {
                  required: true,
                  message: "電話番号を入力してください",
                },
                {
                  max: 15,
                  message: "電話番号は15数字未満である必要があります",
                },
                {
                  pattern: new RegExp("^\\d+$"),
                  message: "番号を入力してください",
                },
              ]}
            >
              <Input allowClear placeholder="例：080-0000-0000" />
            </Form.Item>
            <Form.Item
              name="postalCode"
              label="郵便番号"
              className="px-6"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "郵便番号を入力してください",
                },
                {
                  len: 7,
                  message: "",
                },
              ]}
            >
              <NumericInputComponent
                placeholder="例：4600008"
                maxLength={7}
                allowClear
                onPressEnter={(e) => {
                  e.preventDefault()
                  postalSearchHandler()
                }}
                onChange={(e) => {
                  postalSearchHandler()
                }}
                pattern="[0-9]*"
                inputMode="numeric"
              />
            </Form.Item>
            <Form.Item label="住所1" name="prefecture" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label="住所1"
              name="address1"
              className="px-6"
              rules={[
                {
                  required: true,
                  message: "住所を入力してください",
                },
              ]}
            >
              <Input allowClear placeholder="例：愛知県名古屋市中区" />
            </Form.Item>
            <Form.Item
              label="住所2"
              name="address2"
              className="px-6"
              rules={[
                {
                  required: true,
                  message: "住所を入力してください",
                },
              ]}
            >
              <Input allowClear placeholder="例：錦2丁目12-34" />
            </Form.Item>
            <Form.Item label="住所3" name="address3" className="px-6">
              <Input allowClear placeholder="例：●●ビルディング２階" />
            </Form.Item>

            <Form.Item
              label="メールアドレス１"
              name="email"
              className="px-6 mb-0 "
              rules={[
                {
                  type: "email",
                  message: "メールアドレスを入力してください",
                },
              ]}
            >
              <Input allowClear placeholder="例：xxx@xxx.xxxx.xx" />
            </Form.Item>
            <Form.Item name="notifyPC" className="px-6 my-0 ">
              <Radio.Group size="large">
                <Radio value={1}>許可</Radio>
                <Radio value={0}>拒否</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="メールアドレス2"
              name="emailMobile"
              className="px-6 mb-0 "
              type="email"
              rules={[
                {
                  type: "email",
                  message: "メールアドレスを入力してください",
                },
              ]}
            >
              <Input allowClear placeholder="例：xxx@xxx.xxxx.xx" />
            </Form.Item>
            <Form.Item name="notifyMobile" className="px-6 my-0 ">
              <Radio.Group size="large">
                <Radio value={1}>許可</Radio>
                <Radio value={0}>拒否</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="生年月日" name="birthDate" className="px-6  ">
              <DatePicker
                className="w-full"
                defaultPickerValue={moment("2000-01-01", "YYYY-MM-DD")}
                disabledDate={(currentDate) => currentDate.isAfter()}
                placeholder="例：1970/01/01"
                locale={locale}
                inputReadOnly
                showToday={false}
                allowClear={false}
              />
            </Form.Item>
            <Form.Item
              label="生まれ月"
              name="birthMonth"
              className="px-6  "
              rules={[
                {
                  required: true,
                  message: "生まれ月を入力してください",
                },
              ]}
            >
              <Select
                placeholder="生まれ月"
                allowClear
                showSearch={false}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                mode="single"
              >
                {COMMONS.MONTHS?.map((oc) => (
                  <Option
                    key={oc?.value + ""}
                    value={oc?.value + ""}
                    label={oc?.label}
                  >
                    {oc?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="性別" name="gender" className="px-6 ">
              <Select
                placeholder="性別"
                allowClear
                options={[
                  { value: 1, label: "男性" },
                  {
                    value: 2,
                    label: "女性",
                  },
                  {
                    value: 3,
                    label: "その他",
                  },
                ]}
              />
            </Form.Item>

            <div className="flex justify-center mt-8 px-4">
              <TapAnimationComponent>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={registerProfileMutation.isLoading}
                >
                  本登録する
                </Button>
              </TapAnimationComponent>
            </div>
          </Form>
        </motion.div>
      </Modal>
    </>
  )
}

export default MemberAddModal
