import { Modal, Tag, Divider, Table } from "antd"
import { motion } from "framer-motion"
import * as COMMONS from "common/common"

const POSOrderModal = (props) => {
  const {
    hideMemberPOSOrderDetailModal,
    isPOSOrderDetailsModalVisible,
    orderDetails,
  } = props

  const columns = [
    {
      title: "購入商品",
      dataIndex: "name1",
      align: "center",
      width: 14,
      render: (memberPosDetail) => (
        <>
          <Tag color="green">{memberPosDetail?.name1}</Tag>
          <Tag color="green">{memberPosDetail?.name2}</Tag>
        </>
      ),
    },
    {
      title: "購入数量",
      dataIndex: "volume",
      align: "center",
      width: 4,
    },
    {
      title: "購入金額合計 (円)",
      dataIndex: "price",
      align: "center",
      width: 8,
      render: (price) => price + "円",
    },
    {
      title: "消費税率（％）",
      dataIndex: "taxRate",
      align: "center",
      width: 4,
      render: (taxRate) => (taxRate ? taxRate + "%" : "−"),
    },
  ]
  return (
    <>
      <Modal
        open={isPOSOrderDetailsModalVisible}
        onCancel={hideMemberPOSOrderDetailModal}
        width={800}
        bodyStyle={{
          maxHeight: "85vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        footer={null}
        destroyOnClose
        centered
      >
        <motion.div
          className="flex flex-col justify-center mb-4 "
          variants={COMMONS.ANIMATION_VARIANT_STAGGER_CONTAINER}
          initial="hidden"
          animate="show"
          exit="hidden"
        >
          <Divider>購入金額の明細</Divider>

          <Table
            bordered
            pagination={false}
            size="small"
            columns={columns}
            dataSource={
              orderDetails
                ? orderDetails.map((memberPosDetail) => {
                    return {
                      key: memberPosDetail?.id,
                      name1: memberPosDetail,
                      volume: memberPosDetail?.volume,
                      price: memberPosDetail?.price,
                      taxRate: memberPosDetail?.taxRate,
                    }
                  })
                : []
            }
            scroll={{
              x: 640,
              y: 720,
            }}
            locale={{ emptyText: "購入データがありません。" }}
          />
        </motion.div>
      </Modal>
    </>
  )
}

export default POSOrderModal
