import { useEffect, useRef, useState } from "react"
import Resizer from "react-image-file-resizer"
import moment from "moment"
import { generate } from "@ant-design/colors"
import { Tag } from "antd"

export const IS_CATEGORY_VERSION =
  process.env.REACT_APP_CATEGORY_VERSION === "true"

/* ROUTES */
export const ADMIN_LOGIN_ROUTE = "/login"
export const ADMIN_HOME_ROUTE = "/home"
export const ADMIN_CATEGORIES_ROUTE = "/categories"
export const ADMIN_OCCASIONS_ROUTE = "/occasions"
export const ADMIN_MEMBERS_ROUTE = "/members"
export const ADMIN_CAMPAIGN_MEMBERS_ROUTE = "/campaign-members"
export const ADMIN_AUDIENCES_ROUTE = "/audiences"
export const ADMIN_AUDIENCES_SEARCH_ROUTE = "/audiencesSearch"
export const ADMIN_SETTINGS_ROUTE = "/settings"

export const CLIENT_LOGIN_ROUTE = "/liff/login"
export const CLIENT_POS_LOGIN_ROUTE = "/liff/pos-login"
export const CLIENT_EC_LOGIN_ROUTE = "/liff/ec-login"
export const CLIENT_POS_CONFIRM_ROUTE = "/liff/pos-confirm"
export const CLIENT_REGISTER_ROUTE = "/liff/register"
export const CLIENT_REGISTER_CONFIRM_ROUTE = "/liff/registerConfirm"
export const CLIENT_TEMP_MEMBERSHIP_ROUTE = "/liff/temporaryMembership"
export const CLIENT_CAMPAIGN_ROUTE = "/liff/campaign"
export const CLIENT_MEMBERSHIP_ROUTE = "/liff/membership"
export const CLIENT_CATEGORIES_ROUTE = "/liff/categories"
export const CLIENT_OCCASIONS_ROUTE = "/liff/occasions"
export const CLIENT_OCCURRENCES_ROUTE = "/liff/occurrences"
export const CLIENT_RESERVATION_ROUTE = "/liff/reservation"
export const CLIENT_REGISTRATIONS_ROUTE = "/liff/registrations"
export const CLIENT_REGISTRATION_DETAIL_ROUTE = "/liff/registration/detail"
export const CLIENT_POS_ORDER_DETAIL_ROUTE = "/liff/posOrder/detail"
export const CLIENT_PROFILE_UPDATE_ROUTE = "/liff/update"

export const LINE_ACCESS_ROUTE = "/line-access"
export const LINE_FRIEND_ROUTE = "/line-friend"
export const NOT_FOUND_ROUTE = "/404"
export const PERMISSION_ERROR_ROUTE = "/401"

/* ALERT MESSAGES */
export const ERROR_SYSTEM_MSG = "システムエラー"
export const ERROR_SESSION_MSG = "もう一度ログインしてください"

export const ERROR_401_MSG = "許可が足りないです。"
export const ERROR_404_MSG = "アクセスしたページは存在しません"
export const ERROR_LINE_403_MSG = "LINEアプリからアクセスしてください"
export const ERROR_LINE_FRIEND_MSG = "LINEで友だち追加してアクセスしてください"
export const ERROR_ADMIN_LOGIN_MISMATCH_MSG =
  "ユーザー名またはパスワードが間違っています"
export const ERROR_TELEPHONE_UNIQUE_MSG = "この電話番号はすでに登録されています"
export const ERROR_EC_CONFLICT_MSG =
  "入力されましたメールアドレスとパスワードに誤りがあります。再度ご確認ください。"
export const ERROR_POS_CONFLICT_MSG =
  "電話番号または顧客番号が登録されてません。店舗スタッフにご確認ください。"
export const ERROR_EMAIL_UNIQUE_MSG =
  "このメールアドレスはすでに登録されています"
export const ERROR_EC_EMAIL_USER_EXIST =
  "このメールアドレスでEC側に既にユーザが存在しています"
export const ERROR_TELEPHONE_EMAIL_UNIQUE_MSG =
  "電話番号またはメールアドレスがすでに登録されています"
export const WARN_POSTAL_CODE_WRONG_MSG = "郵便番号を確認してください"
export const WARN_AUDIENCE_NAME_EXIST_MSG =
  "このオーディエンス名はすでに存在しています"
export const WARN_AUDIENCE_COUNT_ZERO_MSG =
  "応募者0人でオーディエンスを作成することはできません"
export const WARN_AUDIENCE_NOT_MATCH_MSG =
  "検索条件が変更されています。一度検索ボタンクリックしてその後作成してください。"
export const WARN_IMAGE_TOO_BIG = "画像サイズが大きすぎます"
export const WARN_IMAGE_SIZE_DIFFERENT = "画像サイズが違います"
export const WARN_CUSTOMER_CODE_NOT_EXIST_MSG =
  "このコードを持つユーザーはありません"
export const WARN_RESERVATION_FULL_MSG =
  "選択した時間帯が満員になったので別の時間帯を選択してください"
export const WARN_RESERVATION_PAST_MSG = "過去に予約を登録することはできません"
export const WARN_CANDIDATES_COUNT_ZERO_MSG =
  "0人から当選者を抽出ことはできません"
export const WARN_PASSWORD_NOT_MATCH_MSG = "パスワードが間違っています"
export const WARN_CUSTOMER_EMAIL_NOT_EXIST_MSG =
  "このメールアドレスを持つユーザーはありません"
export const WARN_TOO_MANY_SYNC_REQUEST_MSG = '誤った情報が繰り返し入力されたため、ログインを制限しました。しばらくしてからお試しください。'
export const INFO_CAMERA_PERMISSION_MSG = "カメラの許可をお願いします"
export const SUCCESS_LOGIN_MSG = "ログインしました。"
export const SUCCESS_LOGOUT_MSG = "ログアウトしました。"
export const SUCCESS_CREATE_MSG = "作成しました。"
export const SUCCESS_REGISTER_MSG = "登録しました。"
export const SUCCESS_ADD_MSG = "追加しました。"
export const SUCCESS_UPDATE_MSG = "保存しました。"
export const SUCCESS_DELETE_MSG = "削除しました。"
export const SUCCESS_LINE_DISCONNECT_MSG = "LINE連携解除しました"
export const SUCCESS_CANCEL_MSG = "キャンセルしました。"
export const SUCCESS_UPLOAD_MSG = "アプロードしました。"
export const SUCCESS_AUDIENCE_SYNC_MSG = "オーディエンス同期しました。"
export const SUCCESS_APPROVED_MSG = "リクエストを受け取りました。"
export const SUCCESS_REJECTED_MSG = "リクエストを断りました。"
export const SUCCESS_MESSAGE_SENT_MSG = "メッセージを送信しました。"
export const SUCCESS_CANDIDATES_SELECT_MSG = "当選者を抽出しました。"
export const SUCCESS_CANDIDATES_RESET_MSG = "当選リストをリセットしました。"
export const SUCCESS_SEARCH_MSG = "検索完了しました。"
export const SUCCESS_EC_INTEGRATION_MSG = "EC側のアカウントと連携しました。"
export const SUCCESS_POS_INTEGRATION_MSG = "POS側のアカウントと連携しました。"

/* RESPONSE TYPE */
export const RESPONSE_PERMISSION_ERROR = 401
export const RESPONSE_SESSION_ERROR = 403
export const RESPONSE_NOT_ACCEPTABLE_ERROR = 406
export const RESPONSE_CONFLICT_ERROR = 409
export const RESPONSE_TOO_MANY_REQUEST_ERROR = 429
export const RESPONSE_SYSTEM_ERROR = 500
export const POS_MEMBER_FOUND_STATUS = "pos member not found"

/* MESSAGE KEY */
export const MESSAGE_SESSION_ERROR_KEY = "SESSION_ERROR"
export const MESSAGE_SYSTEM_ERROR_KEY = "SYSTEM_ERROR"
export const MESSAGE_POS_CONFLICT_ERROR_KEY = "POS_MEMBER_NOT_FOUND"
export const MESSAGE_CAMERA_PERMISSION_KEY = "CAMERA_PERMISSION"

/* AUTH TYPE */
export const AUTH_MASTER = "master"

/* MESSAGE TYPE */
export const MESSAGE_TYPE_TEXT = "text"
export const MESSAGE_TYPE_IMAGE = "image"

/* SYSTEM TYPE */
export const DEFAULT_SYSTEM_TYPE = "イベント"

/* PAGES */
export const PAGE_ADMIN_HOME = "ホーム"
export const PAGE_ADMIN_OCCASIONS = `${DEFAULT_SYSTEM_TYPE}・予約管理`
export const PAGE_ADMIN_CUSTOMERS = "お客様管理"
export const PAGE_ADMIN_CAMPAIGN_CUSTOMERS = "キャンペーン応募者"
export const PAGE_ADMIN_AUDIENCES = "オーディエンス"
export const PAGE_ADMIN_SETTINGS = "設定"

/* FULLCALENDAR KEY */
export const FKEY = "0671443620-fcs-1624419667"
export const BUSINESS_OPEN_TIME = "07:00:00"
export const BUSINESS_OPEN_TIME_VALUE = 7
export const BUSINESS_CLOSE_TIME = "21:00:00"
export const BUSINESS_CLOSE_TIME_VALUE = 21
export const BUSINESS_INTERVAL_TIME_LABEL = "01:00:00"
export const BUSINESS_INTERVAL_TIME = "01:00:00"
export const BUSINESS_INTERVAL_TIME_VALUE = 60 //MUST BE MINUTE | 15 | 30 | 45 | 60

export const DEFAULT_CLIENT_CATEGORIES_SCREEN_TITLE = `ご希望の${DEFAULT_SYSTEM_TYPE}をお選び下さい`
export const DEFAULT_CLIENT_CATEGORIES_SCREEN_SUBTITLE =
  "※予約枠が埋まっていてもご相談ください"

/* OCCASION DEFAULT VALUE */
export const DEFAULT_OCCASION_CANCEL_ALLOWED = false
export const DEFAULT_OCCASION_CANCEL_LIMIT_DAY = 1
export const DEFAULT_OCCASION_CANCEL_LIMIT_HOUR = 18
export const DEFAULT_OCCASION_CANCEL_LIMIT_MINUTE = 0
export const DEFAULT_OCCASION_RESERVATION_LIMIT_ENABLED = false
export const DEFAULT_OCCASION_RESERVATION_LIMIT_DAY = 1
export const DEFAULT_OCCASION_RESERVATION_LIMIT_HOUR = 18
export const DEFAULT_OCCASION_RESERVATION_LIMIT_MINUTE = 0
export const DEFAULT_OCCURRENCE_MAX_PARTICIPATION = 3

export const OCCURRENCE_WARN_COUNT = 5

/* Days of week */
export const DAYS_OF_WEEK = [
  {
    label: "日",
    longLabel: "日曜日",
    value: 7,
    times: [
      {
        startTime: moment(BUSINESS_OPEN_TIME, "HH:mm:ss"),
        endTime: moment(BUSINESS_CLOSE_TIME, "HH:mm:ss"),
      },
    ],
    enabled: true,
  },
  {
    label: "月",
    longLabel: "月曜日",
    value: 1,
    times: [
      {
        startTime: moment(BUSINESS_OPEN_TIME, "HH:mm:ss"),
        endTime: moment(BUSINESS_CLOSE_TIME, "HH:mm:ss"),
      },
    ],
    enabled: true,
  },
  {
    label: "火",
    longLabel: "火曜日",
    value: 2,
    times: [
      {
        startTime: moment(BUSINESS_OPEN_TIME, "HH:mm:ss"),
        endTime: moment(BUSINESS_CLOSE_TIME, "HH:mm:ss"),
      },
    ],
    enabled: true,
  },
  {
    label: "水",
    longLabel: "水曜日",
    value: 3,
    times: [
      {
        startTime: moment(BUSINESS_OPEN_TIME, "HH:mm:ss"),
        endTime: moment(BUSINESS_CLOSE_TIME, "HH:mm:ss"),
      },
    ],
    enabled: true,
  },
  {
    label: "木",
    longLabel: "木曜日",
    value: 4,
    times: [
      {
        startTime: moment(BUSINESS_OPEN_TIME, "HH:mm:ss"),
        endTime: moment(BUSINESS_CLOSE_TIME, "HH:mm:ss"),
      },
    ],
    enabled: true,
  },
  {
    label: "金",
    longLabel: "金曜日",
    value: 5,
    times: [
      {
        startTime: moment(BUSINESS_OPEN_TIME, "HH:mm:ss"),
        endTime: moment(BUSINESS_CLOSE_TIME, "HH:mm:ss"),
      },
    ],
    enabled: true,
  },
  {
    label: "土",
    longLabel: "土曜日",
    value: 6,
    times: [
      {
        startTime: moment(BUSINESS_OPEN_TIME, "HH:mm:ss"),
        endTime: moment(BUSINESS_CLOSE_TIME, "HH:mm:ss"),
      },
    ],
    enabled: true,
  },
]

/* FORM SCROLL CONFIG */
export const FORM_SCROLL_CONFIG = {
  behavior: "smooth",
  block: "top",
  inline: "center",
}

/* THEME COLOR */
export const PRIMARY_COLOR = "#99CA29"
export const PRIMARY_LIGHT_COLOR = "#fdfff0"
export const SECONDARY_COLOR = "#21acd7"
export const SECONDARY_LIGHT_COLOR = "#f0feff"
export const THIRD_COLOR = "#fa8c16"
export const THIRD_LIGHT_COLOR = "#fff7e6"
export const CUSTOM_GREEN = "#8ac926"
export const CUSTOM_LIGHT_GREEN = "#fcfff0"
export const CUSTOM_RED = "#ff595e"
export const CUSTOM_LIGHT_RED = "#fff2f0"
export const CUSTOM_YELLOW = "#FAD717"
export const CUSTOM_LIGHT_YELLOW = "#FEFBE8"
export const CUSTOM_GRAY_COLOR = "#d9d9d9"
export const CUSTOM_CAMPAIGN_COLOR = "#960E0E"
export const GRAY_COLOR = "#9CA3AF"
export const WHITE_COLOR = "#FFF"
export const BLACK_COLOR = "#000"
export const SATURDAY_COLOR = "#00c2ff"
export const SUNDAY_COLOR = "#c40055"

export const THEME_COLORS = [
  "#99CA29",
  "#EA638C",
  "#DB5461",
  "#BB3E03",
  "#E85D75",
  "#C76D7E",
  "#AB92BF",
  "#8D6B94",
  "#4EA5D9",
  "#134074",
  "#005F73",
  "#8DA9C4",
  "#72A1E5",
  "#0A9396",
  "#84A07C",
  "#3C787E",
  "#77878B",
  "#9a8c98",
  "#9F8082",
  "#b5838d",
  "#6d6875",
  "#30343F",
  "#2E382E",
]

export const GET_AUDIENCE_STATUS = (value) => {
  switch (value) {
    case "IN_PROGRESS":
      return (
        <Tag color="#fcbf49" className="mr-0">
          作成中
        </Tag>
      )
    case "READY":
      return (
        <Tag color="#06d6a0" className="mr-0">
          完成
        </Tag>
      )
    case "FAILED":
      return (
        <Tag color="#ff6b6b" className="mr-0">
          エラー
        </Tag>
      )
    case "EXPIRED":
      return (
        <Tag color="#f77f00" className="mr-0">
          期限切れ
        </Tag>
      )
    case "INACTIVE":
      return (
        <Tag color="#38a3a5" className="mr-0">
          無効
        </Tag>
      )
    case "ACTIVATING":
      return (
        <Tag color="#52b788" className="mr-0">
          有効化中
        </Tag>
      )
    default:
      break
  }
}

export const IS_SYNCED_WITH_POS = (response) => {
  return response?.lineId && response?.memberPosId
}
export const IS_REGISTERED_INITIAL_WITH_POS = (response) => {
  return (
    response?.lineId &&
    !response?.memberCode &&
    !response?.postalCode &&
    response?.memberPosId
  )
}

export const OCCURRENCE_TIME_STYLE = (publicSettings) => {
  return {
    borderColor: publicSettings?.PRIMARY_COLOR,
    backgroundColor: publicSettings?.PRIMARY_LIGHT_COLOR,
    color: publicSettings?.PRIMARY_COLOR,
  }
}

export const RESERVATION_STYLE = (publicSettings) => {
  return {
    borderColor: publicSettings?.PRIMARY_COLOR,
    backgroundColor: publicSettings?.PRIMARY_COLOR,
    color: WHITE_COLOR,
  }
}

export const ATTENDED_STYLE = (publicSettings) => {
  return {
    borderColor: SECONDARY_COLOR,
    backgroundColor: WHITE_COLOR,
    color: publicSettings?.PRIMARY_COLOR,
  }
}

export const MAX_CAPACITY_STYLE = (publicSettings) => {
  return {
    borderColor: SECONDARY_COLOR,
    backgroundColor: publicSettings?.PRIMARY_LIGHT_COLOR,
    color: publicSettings?.PRIMARY_COLOR,
  }
}

const REGISTRATION_EXPECTED_STYLE = () => {
  return {
    borderColor: SECONDARY_COLOR,
    backgroundColor: SECONDARY_LIGHT_COLOR,
    color: SECONDARY_COLOR,
  }
}

const REGISTRATION_ATTENDED_STYLE = (publicSettings) => {
  return {
    borderColor: publicSettings?.PRIMARY_COLOR,
    backgroundColor: publicSettings?.PRIMARY_LIGHT_COLOR,
    color: publicSettings?.PRIMARY_COLOR,
  }
}

export const CHECK_IS_ATTENDED = (publicSettings, attended) => {
  if ((attended || 0) === 0) {
    return REGISTRATION_EXPECTED_STYLE(publicSettings)
  } else {
    return REGISTRATION_ATTENDED_STYLE(publicSettings)
  }
}

export const IS_ACTIVE = (end) => {
  if (end) {
    return moment().isSameOrBefore(moment(end), "minute")
  } else {
    return false
  }
}

export const FIND_PERCENTAGE = (x, max) => {
  return Math.round((x / max) * 100)
}

export const OCCURRENCE_STATUS_FULL = "full"
export const OCCURRENCE_STATUS_ALMOST_FULL = "almost-full"
export const OCCURRENCE_STATUS_RESERVED = "reserved"
export const OCCURRENCE_STATUS_AVAILABLE = "available"

export const CHECK_IS_EVENT_FULL = (max, current) => {
  return max > 0 && current > 0 && max === current
}

export const GET_LEFT_SLOTS = (max, current) => {
  return max - current
}

export const IS_ALREADY_RESERVED = (registrations, occurrenceId) => {
  if (registrations && registrations.length > 0) {
    const isReserved = registrations.filter((registration) => {
      if (registration?.occurrences) {
        return registration.occurrences.find(
          (ro) => ro?.occurrenceId === occurrenceId
        )
      } else {
        return false
      }
    })

    return isReserved.length > 0 ? true : false
  } else {
    return false
  }
}

export const CAN_RESERVE = (
  slotCount,
  registrations,
  occurrenceId,
  max,
  current
) => {
  if (IS_ALREADY_RESERVED(registrations, occurrenceId)) {
    return false
  }

  if (CHECK_IS_EVENT_FULL(max, current)) {
    return false
  }

  if (GET_LEFT_SLOTS(max, current) < slotCount) {
    return false
  }

  return true
}

export const ARRAY_RANGE_CREATOR = (start, end, step) => {
  return Array(Math.ceil((end - start) / step))
    .fill(start)
    .map((x, y) => x + y * step)
}

export function USE_IS_MOUNTED_REF() {
  const isMountedRef = useRef(null)

  useEffect(() => {
    isMountedRef.current = true
    return () => (isMountedRef.current = false)
  })

  return isMountedRef
}

export function COLOR_ADJUST(color, amount) {
  const colors = generate(color)

  return colors[amount - 100] || colors[0]
}

export const RE_ORDER = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function POSTAL_CODE_INSERT_CHARACTER(str, index, value) {
  return str.substr(0, index) + value + str.substr(index)
}

export const RESIZE_FILE = (file, type) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri)
      },
      type
    )
  })

export const SESSION_STORAGE_USER_INFO_KEY = "PREGIO:USER_INFO"

const GET_SESSION_STORAGE_OR_DEFAULT = (key, defaultValue) => {
  const stored = sessionStorage.getItem(key)
  if (!stored) {
    return defaultValue
  }
  return JSON.parse(stored)
}

export const USE_SESSION_STORAGE = (key, defaultValue) => {
  const [value, setValue] = useState(
    GET_SESSION_STORAGE_OR_DEFAULT(key, defaultValue)
  )

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

const GET_LOCAL_STORAGE_OR_DEFAULT = (key, defaultValue) => {
  const stored = localStorage.getItem(key)
  if (!stored) {
    return defaultValue
  }
  return JSON.parse(stored)
}

export const USE_LOCAL_STORAGE = (key, defaultValue) => {
  const [value, setValue] = useState(
    GET_LOCAL_STORAGE_OR_DEFAULT(key, defaultValue)
  )

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

export const EXTRACT_FILENAME_HEADER = (headers, defaultFilename) => {
  const disposition = headers['content-disposition'] || headers['Content-Disposition']
  const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
  const filename = (matches != null && matches[1]) ? decodeURIComponent(matches[1].replace(/['"]/g, '')) : defaultFilename;
  return filename
}

export const ANIMATION_VARIANT_STAGGER_CONTAINER = {
  hidden: {
    transition: {
      staggerChildren: 0.01,
      staggerDirection: -1,
      when: "afterChildren",
    },
  },
  show: {
    transition: {
      staggerChildren: 0.04,
      staggerDirection: 1,
      when: "beforeChildren",
    },
  },
}

export const ANIMATION_VARIANT_STAGGER_ITEM = {
  hidden: {
    opacity: 0,
    // scale: 1,
    transition: { type: "linear" },
  },
  show: {
    opacity: 1,
    // scale: [0.95, 1],
    transition: { type: "linear" },
  },
}

export const MONTHS = [
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
]

export const PREFECTURES = [
  { value: 1, label: "北海道" },
  { value: 2, label: "青森県" },
  { value: 3, label: "岩手県" },
  { value: 4, label: "宮城県" },
  { value: 5, label: "秋田県" },
  { value: 6, label: "山形県" },
  { value: 7, label: "福島県" },
  { value: 8, label: "茨城県" },
  { value: 9, label: "栃木県" },
  { value: 10, label: "群馬県" },
  { value: 11, label: "埼玉県" },
  { value: 12, label: "千葉県" },
  { value: 13, label: "東京都" },
  { value: 14, label: "神奈川県" },
  { value: 15, label: "新潟県" },
  { value: 16, label: "富山県" },
  { value: 17, label: "石川県" },
  { value: 18, label: "福井県" },
  { value: 19, label: "山梨県" },
  { value: 20, label: "長野県" },
  { value: 21, label: "岐阜県" },
  { value: 22, label: "静岡県" },
  { value: 23, label: "愛知県" },
  { value: 24, label: "三重県" },
  { value: 25, label: "滋賀県" },
  { value: 26, label: "京都府" },
  { value: 27, label: "大阪府" },
  { value: 28, label: "兵庫県" },
  { value: 29, label: "奈良県" },
  { value: 30, label: "和歌山県" },
  { value: 31, label: "鳥取県" },
  { value: 32, label: "島根県" },
  { value: 33, label: "岡山県" },
  { value: 34, label: "広島県" },
  { value: 35, label: "山口県" },
  { value: 36, label: "徳島県" },
  { value: 37, label: "香川県" },
  { value: 38, label: "愛媛県" },
  { value: 39, label: "高知県" },
  { value: 40, label: "福岡県" },
  { value: 41, label: "佐賀県" },
  { value: 42, label: "長崎県" },
  { value: 43, label: "熊本県" },
  { value: 44, label: "大分県" },
  { value: 45, label: "宮崎県" },
  { value: 46, label: "鹿児島県" },
  { value: 47, label: "沖縄県" },
]

export const GENDERS = [
  { value: 1, label: "男性" },
  {
    value: 2,
    label: "女性",
  },
  {
    value: 3,
    label: "その他",
  },
]
export const NOTIFY_OPTIONS = [
  { value: 0, label: "正常" },
  {
    value: 1,
    label: "エラー",
  },
  {
    value: 9,
    label: "止め",
  },
]

export const STORE_NAMES = [
  { value: 1, label: "ﾋﾟｵﾝ清水店" },
  { value: 2, label: "ﾋﾟｵﾝ富士店" },
  { value: 3, label: "ﾋﾟｵﾝ静岡店" },
  { value: 4, label: "ｸﾞﾚｰｽﾋﾟｵﾝ" },
  { value: 5, label: "ﾈｯﾄ事業部" },
  { value: 6, label: "ﾋﾟｵﾝ長泉店" },
  { value: 7, label: "G&m沼津店" },
  { value: 9, label: "物流本部" },
]

export const RANKS = [
  { value: 'ノーマル会員', label: 'ノーマル会員' },
  { value: 'レギュラー会員', label: 'レギュラー会員' },
  { value: 'シルバー会員', label: 'シルバー会員' },
  { value: 'ゴールド会員', label: 'ゴールド会員' },
  { value: 'プラチナ会員', label: 'プラチナ会員' },
]