import { Button, Divider, Image, Modal, Descriptions } from "antd"
import {
  EyeOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
} from "@ant-design/icons"
import { motion } from "framer-motion"
import moment from "moment"

import * as COMMONS from "common/common"
import { TapAnimationComponent } from "components"

const DetailModal = (props) => {
  const { isMemberDetailModalVisible, hideMemberDetailModal, currentMember } =
    props

  return (
    <>
      <Modal
        title="お客様詳細"
        open={isMemberDetailModalVisible}
        onCancel={hideMemberDetailModal}
        bodyStyle={{
          maxHeight: "85vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        footer={null}
        destroyOnClose
        centered
      >
        <motion.div
          className="flex flex-col justify-center"
          variants={COMMONS.ANIMATION_VARIANT_STAGGER_CONTAINER}
          initial="hidden"
          animate="show"
          exit="hidden"
        >
          <motion.div variants={COMMONS.ANIMATION_VARIANT_STAGGER_ITEM}>
            <div className="flex justify-center">
              {currentMember?.picUrl ? (
                <Image
                  preview={{
                    mask: <EyeOutlined />,
                    src: currentMember.picUrl,
                    maskClassName: "rounded-full",
                  }}
                  width={100}
                  height={100}
                  style={{
                    border: `4px solid ${COMMONS.WHITE_COLOR}`,
                  }}
                  className="rounded-full"
                  src={`${currentMember.picUrl}/large`}
                  fallback="/no-image.png"
                />
              ) : (
                <Image
                  preview={false}
                  width={100}
                  height={100}
                  style={{
                    border: `4px solid ${COMMONS.WHITE_COLOR}`,
                  }}
                  className="rounded-full"
                  src="/no-image.png"
                />
              )}
            </div>
            <div className="mb-8">
              <p className="text-center font-bold text-xl">
                {currentMember?.lastName || "ー"}{" "}
                {currentMember?.firstName || "ー"}様
              </p>
              <p className="text-center text-sm">
                （{currentMember?.lastNameKana || "ー"}{" "}
                {currentMember?.firstNameKana || "ー"}）
              </p>
            </div>
          </motion.div>
          <motion.div variants={COMMONS.ANIMATION_VARIANT_STAGGER_ITEM}>
            <Divider>会員情報</Divider>
            <Descriptions
              labelStyle={{ width: "200px" }}
              column={1}
              layout="horizontal"
              bordered
            >
              <Descriptions.Item label="顧客番号" className="text-center">
                {currentMember?.memberPosId || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="ID" className="text-center">
                {currentMember?.memberId || "ー"}
              </Descriptions.Item>

              <Descriptions.Item label="ポイント数" className="text-center">
                {currentMember?.pointPos || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="有効期限" className="text-center">
                {currentMember?.purchaseLatestAt
                  ? moment(currentMember?.purchaseLatestAt)
                    .add(1, "year")
                    .subtract(1, "day")
                    .format("YYYY年MM月DD日")
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="入会日" className="text-center">
                {currentMember?.memberSince
                  ? moment(currentMember.memberSince).format("YYYY年MM月DD日")
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="最終来店日" className="text-center">
                {currentMember?.purchaseLatestAt
                  ? moment(currentMember.purchaseLatestAt).format(
                    "YYYY年MM月DD日"
                  )
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="顧客ランク" className="text-center">
                {currentMember?.rank ? currentMember.rank : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="本年度購入金額" className="text-center">
                {currentMember?.totalPurchase
                  ? currentMember.totalPurchase + " 円"
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="会員状況" className="text-center">
                {currentMember?.membershipCategory
                  ? currentMember.membershipCategory === 0
                    ? "仮会員"
                    : currentMember.membershipCategory === 1
                      ? "本会員"
                      : "ー"
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="EC連携" className="text-center">
                {currentMember?.memberEcId ? (
                  <CheckCircleFilled className="text-custom-blue text-2xl" />
                ) : (
                  <CloseCircleOutlined className="text-custom-blue text-2xl" />
                )}
              </Descriptions.Item>
            </Descriptions>

            <Divider>個人情報</Divider>

            <Descriptions
              labelStyle={{ width: "200px" }}
              column={1}
              layout="horizontal"
              bordered
            >
              <Descriptions.Item label="LINE名" className="text-center">
                {currentMember?.displayName || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="電話番号" className="text-center">
                {currentMember?.telephone || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="住所" className="text-center">
                〒
                {`${currentMember?.postalCode
                    ? COMMONS.POSTAL_CODE_INSERT_CHARACTER(
                      currentMember.postalCode,
                      3,
                      "-"
                    )
                    : "ー"
                  } 
                ${currentMember?.prefecture || ""}
                ${currentMember?.address || ""}
                ${currentMember?.building || ""}`}
              </Descriptions.Item>
              <Descriptions.Item
                label="PCメールアドレス"
                className="text-center"
              >
                {currentMember?.email || "ー"}
              </Descriptions.Item>
              <Descriptions.Item
                label="携帯メールアドレス"
                className="text-center"
              >
                {currentMember?.emailMobile || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="生年月日" className="text-center">
                {currentMember?.birthDate
                  ? moment(currentMember.birthDate).format("YYYY年MM月DD日")
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="生まれ月" className="text-center">
                {currentMember?.birthMonth
                  ? COMMONS.MONTHS.find(
                    (bm) => bm?.value === currentMember?.birthMonth
                  )?.label + "月"
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="性別" className="text-center">
                {currentMember?.gender
                  ? COMMONS.GENDERS.find(
                    (rc) => rc?.value === currentMember.gender
                  )?.label
                  : "ー"}
              </Descriptions.Item>
            </Descriptions>
            <Divider>顧客区分</Divider>
            <Descriptions
              labelStyle={{ width: "200px" }}
              column={1}
              layout="horizontal"
              bordered
            >
              <Descriptions.Item label="顧客ランク" className="text-center">
                {currentMember?.rank || "ー"}
              </Descriptions.Item>
              <Descriptions.Item
                label="PCメール配信区分"
                className="text-center"
              >
                {currentMember?.notifyPC === 1 ? (
                  <CheckCircleFilled className="text-custom-blue text-2xl" />
                ) : currentMember?.notifyPC === 0 ? (
                  <CloseCircleOutlined className="text-custom-blue text-2xl" />
                ) : (
                  "ー"
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="携帯メール配信区分"
                className="text-center"
              >
                {currentMember?.notifyMobile === 1 ? (
                  <CheckCircleFilled className="text-custom-blue text-2xl" />
                ) : currentMember?.notifyMobile === 0 ? (
                  <CloseCircleOutlined className="text-custom-blue text-2xl" />
                ) : (
                  "ー"
                )}
              </Descriptions.Item>
              <Descriptions.Item label="ブラック区分" className="text-center">
                {currentMember?.blackCategory === 1 ? (
                  <CheckCircleFilled className="text-custom-blue text-2xl" />
                ) : currentMember?.blackCategory === 0 ? (
                  <CloseCircleOutlined className="text-custom-blue text-2xl" />
                ) : (
                  "ー"
                )}
              </Descriptions.Item>
              <Descriptions.Item label="DM発送区分" className="text-center">
                {currentMember?.notifyDM === 1 ? (
                  <CheckCircleFilled className="text-custom-blue text-2xl" />
                ) : currentMember?.notifyDM === 0 ? (
                  <CloseCircleOutlined className="text-custom-blue text-2xl" />
                ) : (
                  "ー"
                )}
              </Descriptions.Item>
            </Descriptions>
          </motion.div>

          <Divider />
          <motion.div
            className="flex justify-center"
            variants={COMMONS.ANIMATION_VARIANT_STAGGER_ITEM}
          >
            <TapAnimationComponent>
              <Button
                size="large"
                className="px-12"
                onClick={hideMemberDetailModal}
              >
                閉じる
              </Button>
            </TapAnimationComponent>
          </motion.div>
        </motion.div>
      </Modal>
    </>
  )
}

export default DetailModal
