import { useState } from "react"
import { Button, Divider, Modal, Tooltip, Table, Tag } from "antd"
import { UnorderedListOutlined } from "@ant-design/icons"
import { motion } from "framer-motion"
import moment from "moment"

import * as COMMONS from "common/common"
import { TapAnimationComponent, POSOrderModalComponent } from "components"
const POSModal = (props) => {
  const {
    isPOSDetailsModalVisible,
    hideMemberPOSDetailModal,
    memberPOSDetails,
  } = props
  const [isPOSOrderDetailsModalVisible, setIsPOSOrderDetailsModalVisible] =
    useState(false)
  const [orderDetails, setOrderDetails] = useState()

  const showMemberPOSOrderDetailModal = (details) => {
    setIsPOSOrderDetailsModalVisible(true)
    setOrderDetails(details)
  }

  const hideMemberPOSOrderDetailModal = () => {
    setIsPOSOrderDetailsModalVisible(false)
  }
  const columns = [
    {
      title: "購入日",
      dataIndex: "soldDate",
      align: "center",
      width: 100,
      render: (soldDate) => (
        <>{soldDate ? moment(soldDate).format("YYYY年MM月DD日") : "ー"}</>
      ),
    },
    {
      title: "売上番号",
      dataIndex: "posOrderId",
      align: "center",
      width: 100,
      render: (posOrderId) => (
        <>{posOrderId ? <p color="green">{posOrderId}</p> : "ー"}</>
      ),
    },
    {
      title: "購入金額合計 (円)",
      dataIndex: "total",
      align: "center",
      width: 100,
      render: (total) => total + "円",
    },
    {
      title: "購入店舗",
      dataIndex: "storeCode",
      align: "center",
      width: 100,
      render: (memberPosDetail) =>
        memberPosDetail?.source === "pos" ? (
          <Tag color="blue">
            {
              COMMONS.STORE_NAMES.find(
                (c) => c?.value === memberPosDetail.storeCode
              )?.label
            }
          </Tag>
        ) : (
          <Tag color="red">WEB</Tag>
        ),
    },
    {
      title: "",
      dataIndex: "details",
      align: "center",
      width: 50,
      render: (details) => (
        <>
          <TapAnimationComponent className="inline-block">
            <Tooltip title="詳細" placement="top">
              <Button
                type="primary"
                size="small"
                className="m-1"
                icon={<UnorderedListOutlined />}
                onClick={() => {
                  showMemberPOSOrderDetailModal(details)
                }}
              />
            </Tooltip>
          </TapAnimationComponent>
        </>
      ),
    },
  ]
  return (
    <>
      <Modal
        width={1000}
        title="お客様詳細"
        open={isPOSDetailsModalVisible}
        onCancel={hideMemberPOSDetailModal}
        bodyStyle={{
          maxHeight: "85vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        footer={null}
        destroyOnClose
        centered
      >
        <motion.div
          className="flex flex-col justify-center"
          variants={COMMONS.ANIMATION_VARIANT_STAGGER_CONTAINER}
          initial="hidden"
          animate="show"
          exit="hidden"
        >
          <Divider>購入履歴</Divider>

          <Table
            bordered
            size="small"
            columns={columns}
            dataSource={
              memberPOSDetails
                ? memberPOSDetails.map((memberPosDetail) => {
                    return {
                      key: memberPosDetail?.id,
                      posOrderId: memberPosDetail?.id,
                      soldDate: memberPosDetail?.soldDate,
                      storeCode: memberPosDetail,
                      details: memberPosDetail?.details,
                      total: memberPosDetail?.total,
                    }
                  })
                : []
            }
            scroll={{
              x: 640,
              y: 720,
            }}
            locale={{ emptyText: "購入データがありません。" }}
          />
          <Divider />
          <motion.div
            className="flex justify-center"
            variants={COMMONS.ANIMATION_VARIANT_STAGGER_ITEM}
          >
            <TapAnimationComponent>
              <Button
                size="large"
                className="px-12"
                onClick={hideMemberPOSDetailModal}
              >
                閉じる
              </Button>
            </TapAnimationComponent>
          </motion.div>
        </motion.div>
      </Modal>
      <POSOrderModalComponent
        isPOSOrderDetailsModalVisible={isPOSOrderDetailsModalVisible}
        hideMemberPOSOrderDetailModal={hideMemberPOSOrderDetailModal}
        orderDetails={orderDetails}
      />
    </>
  )
}

export default POSModal
